<template>
    <div v-if="loading == false">
        <ChirpReport
            :data_source="rows"
            :export_report="export_report"
            :target_page="targetPage"
            :totalCount="totalCount"
            :loadingColumns="loadingTable"
            :column_meta="columnMeta"
            :storeKey="storeKey"
            :useOverride="useOverride"
            :hide_speed_date="false"
            preFormattedData
            preformatted-column-names
            ignoreDateConversion
            @cancelOverride="useOverride = false"
        />
    </div>
</template>
<script>
    import { tryGetFilter } from '@/util/tryGetFilter';

    const api_root = 'reports';
    const report = 'appt_status';
    import ChirpReport from '@/components/general/list/ChirpReport';
    import dayjs from '@/util/dayjs';

    export default {
        name: 'ApptStatus',
        components: { ChirpReport },
        props: {
            overrideFilter: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                rows: [],
                targetPage: '',
                useOverride: false,
                totalCount: 0,
                loading: true,
                loadingTable: false,
                criteriaProps: {},
                columnMeta: [
                    { field: 'id', headerText: 'ID', hidden: true, allowSorting: false },
                    { field: 'appt_date', headerText: 'Appointment Date & Time', allowSorting: true },
                    { field: 'appt_name', headerText: 'Appointment Name', allowSorting: true },
                    { field: 'client_name', headerText: 'Client', allowSorting: true },
                    { field: 'counselors', headerText: 'Counselors', allowSorting: true },
                    { field: 'related_services', headerText: 'Related Services', allowSorting: true },
                    { field: 'appt_status', headerText: 'Appointment Status', allowSorting: true },
                    { field: 'total_fees', headerText: 'Total Fees', allowSorting: true },
                    { field: 'client_resp', headerText: 'Client Responsibilities', allowSorting: true },
                    { field: 'not_invoiced', headerText: 'Not Invoiced', allowSorting: true },
                    { field: 'client_paid', headerText: 'Client Paid', allowSorting: true },
                    { field: 'client_unpaid', headerText: 'Client Unpaid', allowSorting: true },
                ],
                export_report: 'appt_status'
            };
        },
        computed: {
            criteria() {
                return this.useOverride && this.overrideFilter ? this.overrideCriteria : this.savedCriteria;
            },
            savedCriteria() {
                return tryGetFilter(this.$store, this.storeKey);
            },
            overrideCriteria() {
                return this.$store.getters['filters/overrideCriteria'](this.storeKey);
            },
            storeKey() {
                return this.pageMeta?.page || 'apptStatus';
            },
        },
        methods: {
            async load_data(criteria = this.criteria) {
                const body = {
                    criteria: {
                        ...criteria,
                        report,
                    },
                };
                const res = await this.$api.post(`/${api_root}`, body);
                this.rows = res.data.rows.map((row) => {
                    return {
                        id: row.id,
                        appt_date: row.appt_date ? dayjs(row.appt_date).format('MM/D/YY hh:mm A') : '',
                        appt_name: row.appt_name,
                        client_name: row.client_name,
                        counselors: row.counselors,
                        related_services: row.related_services,
                        appt_status: row.appt_status,
                        total_fees: row.total_fees ? this.$getCurrency(row.total_fees) : this.$getCurrency(0),
                        client_resp: row.client_resp ? this.$getCurrency(row.client_resp) : this.$getCurrency(0),
                        not_invoiced: row.not_invoiced ? this.$getCurrency(row.not_invoiced) : this.$getCurrency(0),
                        client_paid: row.client_paid ? this.$getCurrency(row.client_paid) : this.$getCurrency(0),
                        client_unpaid: row.client_unpaid ? this.$getCurrency(row.client_unpaid) : this.$getCurrency(0),
                    };
                });
                this.totalCount = res.data.total_count;

                if (
                    !criteria?.default_date_range ||
                    criteria?.default_date_range !== res.data.default_date_range
                ) {
                    this.$store.commit('filters/updateFilter', {
                        stateKey: this.storeKey,
                        criteria: {
                            ...criteria,
                            default_date_range: res.data.default_date_range,
                        },
                    });
                }

                this.$nextTick(() => {
                    this.loading = false;
                });
            },
            async init() {
                if (this.overrideFilter) {
                    this.useOverride = true;
                    this.$store.commit('filters/applyOverride', {
                        stateKey: this.storeKey,
                        criteria: this.overrideFilter,
                    });
                } else {
                    this.$store.commit('filters/updateFilter', {
                        stateKey: this.storeKey,
                        criteria: {
                            ...this.criteria,
                            column: [],
                        },
                    });
                }

                if (!this.criteria.sort || (this.criteria.sort && Object.keys(this.criteria.sort).length === 0)) {
                    this.$store.commit('filters/applyDefaultDateSort', {
                        stateKey: this.storeKey,
                        customDateKey: 'appt_date',
                    });
                }

                await this.load_data();
            },
        },
        async created() {
            await this.init();
        },
        watch: {
            criteria: {
                deep: true,
                async handler(newVal, oldVal) {
                    let updatedNewVal = { ...newVal };

                    if (Object.keys(newVal.sort).length === 0){
                        updatedNewVal.sort = oldVal.sort;
                    }

                    const newStr = JSON.stringify(updatedNewVal);
                    const oldStr = JSON.stringify(oldVal);
                    if (newStr !== oldStr) {
                        await this.load_data(updatedNewVal);
                    }
                },
            },
        },
    };
</script>
